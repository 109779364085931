<template>
    <div class="header">
        <div class="content">
            <a class="logo" href="#download">
                <img src="../../assets/Bear.png" alt="logo" class="icon">
                <img src="../../assets/name.svg" alt="name" class="name">
            </a>
            <nav class="navs">
                <template v-for="link in links">
                    <span v-if="link.path === '/buahKita'" @click="toBuahKita" class="nav" :key="link.name" :href="link.path">{{link.name}}</span>
                    <a v-else class="nav" :key="link.name" :href="link.path">{{link.name}}</a>
                </template>
            </nav>
        </div>
    </div>
</template>

<script>
import { links } from "@/utils.js";
export default {
    data() {
        return {
            links,
        };
    },
    methods: {
        toBuahKita() {
            this.$router.push({ path: "/buahKita" });
        },
    }
};
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 100;
}
.content {
    display: flex;
    align-items: center;
    width: 1040px;
    margin: 0 auto;
    height: 64px;
    justify-content: space-between;
    line-height: 64px;
    .logo {
        display: flex;
        margin-left: 20px;
        align-items: center;
        .icon {
            width: 40px;
            height: 40px;
            margin: 0 20px;
        }
        .name {
            width: 94px;
            height: 20px;
        }
    }
    .navs {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 50px;
        .nav {
            height: 100%;
            color: #02283f;
            text-decoration: none;
            cursor: pointer;
        }
    }
}
</style>