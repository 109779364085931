<template>
    <div class="pc_home">
        <Header />
        <Download />
        <About />
        <Customers />
        <Partners />
        <Merchants />
        <NewsRoom />
        <Contact />
    </div>
</template>

<script>
import Header from "@/components/pc/Header.vue";
import Download from "@/components/pc/Download.vue";
import About from "@/components/pc/About.vue";
import Customers from "@/components/pc/Customers.vue";
import Partners from "@/components/pc/Partners.vue";
import Merchants from "@/components/pc/Merchants.vue";
import NewsRoom from "@/components/pc/NewsRoom.vue";
import Contact from "@/components/pc/Contact.vue";
export default {
    components: {
        Header,
        Download,
        About,
        Customers,
        Partners,
        Merchants,
        NewsRoom,
        Contact,
    },
    data() {
        return {};
    },
    mounted() {
        document.querySelector(this.$route.hash).scrollIntoView();
    },
};
</script>

<style lang="scss" scoped>
</style>
